<template>
  <v-list-item
    class="outlined"
    dense
    @click="$emit('select', { data: item, type: 'passport', selected: false })"
    :title="item.label"
  >
    <v-list-item-content>
      <v-list-item-title>{{ item.properties.object_name }}</v-list-item-title>
      <v-list-item-subtitle>{{ formatValue('passport.type', Number(item.properties.type)) }}</v-list-item-subtitle>
      <v-list-item-subtitle>{{ item.properties.territorial_affiliation }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <BaseBtn
        is-type-icon
        @click="$emit('hide', item)"
        :icon="eyeIcon"
      >
      </BaseBtn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import BaseBtn from '@/components/base/UI/BaseBtn'

export default {
  name: 'ListItem',
  components: { BaseBtn },
  mixins: [formatters],
  props: {
    item: Object,
    hiddenList: {
      type: Object
    }
  },
  computed: {
    isHidden () {
      return this.hiddenList && this.hiddenList[this.item.properties.id]
    },
    eyeIcon () {
      return this.isHidden ? 'mdi-eye-off' : 'mdi-eye'
    }
  }
}
</script>

<style scoped>
  .outlined {
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    margin-bottom: 4px;
    padding: 0 8px;
  }
</style>